import React from "react";
import styles from "./Header.module.css";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Logout from "../../../../containers/Auth/Logout/Logout";
import * as routeConstant from "../../../../common/RouteConstants";
import { useNavigate,useLocation } from "react-router-dom";
import { UPDATE_CSET_ASSESSMENT } from "../../../../graphql/mutations/CSETAssessment";
import { useMutation } from "@apollo/client";
import { useErrorBoundary } from 'react-error-boundary';
import * as Sentry from "@sentry/react";

export const Header: React.FC = () => {
  const history = useNavigate();
  const location = useLocation();
  const { resetBoundary } = useErrorBoundary();
  const [updateAssessement] = useMutation(UPDATE_CSET_ASSESSMENT);
  const RAcontact = sessionStorage.getItem("ra_contact") || Logout();
  const userdata =  sessionStorage.getItem("ra_user") || Logout();
  const ra_user = userdata ? JSON.parse(userdata) : "";
  const is_link_assessment =  Boolean(sessionStorage.getItem("is_link_authenticated")) || false; 
  const user = JSON.parse(RAcontact) ? JSON.parse(RAcontact) || "" : Logout();
  let parseParam:any;
  if(sessionStorage.getItem("param")){
    const paramObj:any = sessionStorage.getItem("param");
    parseParam = JSON.parse(paramObj);
  }
  const param = location?.state ? location?.state[Object.keys(location?.state)[0]] :parseParam;
  const getHelpManual = () => {
    resetBoundary();
    let ra_manual = process.env.REACT_APP_HELP_URL;
    window.open(ra_manual, "_blank");
  };
  const getHome = () => {
    resetBoundary();
    if(!is_link_assessment){
      if(ra_user?.user?.role?.name === "Administrator"){
        history(routeConstant.ADMIN_DASHBOARD);
      }else{
        if(param && param.ccAssessmentInfo && param.ccAssessmentInfo.id){
          updateAssessement({
            variables: {
              id: param.ccAssessmentInfo.id,
              assessment_type: param.ccAssessmentInfo.assessment_type,
              is_editing_assessment: "0"
            },
          }).then((res:any) => {
            history(routeConstant.CLIENT);
          }).catch((err) => {
            Sentry.captureException(err)
            history(routeConstant.CLIENT);
          })
        }else{
          history(routeConstant.CLIENT);
        }
        localStorage.clear();
      }
    }
  };
  console.log("paramHeader",param)
  const onLogoutHandler = () => {
    if(param && param.ccAssessmentInfo && param.ccAssessmentInfo.id){
      updateAssessement({
        variables: {
          id: param.ccAssessmentInfo.id,
          assessment_type: param.ccAssessmentInfo.assessment_type,
          is_editing_assessment: "0"
        },
      }).then((res:any) => {
        Logout();
      }).catch((err) => {
        Sentry.captureException(err)
        Logout();
      })
    }else{
      Logout();
    }
  }
  return (
    <div className={styles.Header} data-testid="Header">
      <Card className={styles.root}>
        <Grid container>
          <Grid item xs={12} className={styles.RALogoImg}>
            <div>
              <img
                src={`${process.env.PUBLIC_URL}/RA360.png`}
                alt="user icon"
                onClick={getHome}
              />
            </div>
          </Grid>
          <Grid item xs={12} className={styles.userInfo}>
            {!is_link_assessment && (<span className={styles.userNameLabel}>
              {user.name}&nbsp;{user.last_name} &nbsp;&nbsp;|&nbsp;&nbsp;
            </span>)}
            {!is_link_assessment && (<span className={styles.userNameLabel}>
              <a className={styles.logout} onClick={getHelpManual}>
                Help
              </a>
              &nbsp;&nbsp;|&nbsp;&nbsp;
            </span>)}
            <span className={styles.userNameLabel}>
              <a className={styles.logout} onClick={onLogoutHandler}>
                Logout
              </a>
            </span>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default Header;
