import React, { useEffect, useState } from "react";
import styles from "./Standards.module.css";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Button } from "../../../components/UI/Form/Button/Button";
import * as routeConstant from "../../../common/RouteConstants";
import { useNavigate, useLocation } from "react-router-dom";
import { CSET_URL } from "../../../config/index";
import FormControlLabel from "@mui/material/FormControlLabel";
import TopStepper from "../../../components/UI/Layout/Navigation/TopStepper/TopStepper";
import stepper from "../../../common/csetStepperMenu.json";
import { setStepper } from "../../../common/SetStepper";
import { setActiveFormStep } from "../../../services/Data";
import SimpleBackdrop from "../../../components/UI/Layout/Backdrop/Backdrop";
import Alert from "../../../components/UI/Alert/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import logout from "../../../containers/Auth/Logout/Logout";
import { FormControl } from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import { HISTORY } from "../../../graphql/mutations/AuditTrail";
import { useMutation, useApolloClient } from "@apollo/client";
import * as Sentry from "@sentry/react";
import * as CSET from "../../../common/CSET";
import { sentry_error_catch } from "../../../common/sentry_error_catch";
import { CANCEL_MSG, ERROR_MESSAGE } from "../../../common/MessageConstants";
import { DialogBox } from "../../../components/UI/DialogBox/DialogBox";
import { useErrorBoundary } from "react-error-boundary";
import { UPDATE_CSET_ASSESSMENT } from "../../../graphql/mutations/CSETAssessment";

export const Standards: React.FC = (props: any) => {
  const history = useNavigate();
  const { showBoundary } = useErrorBoundary();
  const location = useLocation();
  const stepperObject = stepper;
  const client = useApolloClient();
  const [stepperObj, setStepperObj] = useState<any>({ path: "" });
  const authToken = sessionStorage.getItem("ra_token") || logout();
  const is_link_assessment =  Boolean(sessionStorage.getItem("is_link_authenticated")) || false;
  const [standardObj, setStandardObj] = useState<any>();
  const [standardArray, setStandardArray] = useState<any>([]);
  const [questionsCount, setQuestionsCount] = useState<any>("0");
  const [requirementCount, setRequirementCount] = useState<any>("0");
  const [modeType, setModeType] = useState("");
  const [queDisable, setQueDisable] = useState<any>(false);
  const [reqDisable, setReqDisable] = useState<any>(false);
  const [checkedValue, setCheckedValue] = useState<any>([]);
  const [param, setParam] = useState<any>(
    location.state[Object.keys(location.state)[0]]
  );
  const [loading, setLoading] = useState(false);
  const [showQuestionMode, setShowQuestionMode] = useState(true);
  const [showRequirementMode, setShowRequirementMode] = useState(true);
  const [openDialogBox, setOpenDialogBox] = useState<boolean>(false);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const [updateAssessment] = useMutation(UPDATE_CSET_ASSESSMENT);
  const [auditTrail] = useMutation(HISTORY);

  useEffect(() => {
    setLoading(true);
    setParam(location.state[Object.keys(location.state)[0]]);
    getStandardSelection();
    getQuestionList();
  }, []);

  useEffect(() => {
    if (questionsCount === 0 && requirementCount === 0) {
      setModeType("Q");
      setQueDisable(false);
      setReqDisable(false);
    }

    if (questionsCount === 0 && requirementCount !== 0) {
      setQueDisable(true);
      setModeType("R");
    }
    if (questionsCount !== 0 && requirementCount !== 0) {
      setQueDisable(false);
    }
  }, [requirementCount && questionsCount]);

  useEffect(() => {
    setStepper(
      client,
      stepperObject.standards.name,
      stepperObject.standards.value
    );
    setActiveFormStep(2);
  }, []);

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };
  const headerObj = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  const setMode = async (type: any) => {
    try {
      await fetch(`${CSET_URL}api/setmode?mode=${type}`, {
        method: "POST",
        headers: headerObj,
        body: JSON.stringify(CSET.MODE_REQUEST),
      })
        .then((data) => {
          getStandardSelection();
        })
        .catch((err:any) => {
          sentry_error_catch(err,setLoading,setFormState)
        });
    } catch (err:any) {
      sentry_error_catch(err,setLoading,setFormState)
    }
  };

  const getQuestionList = async (reqdata?: any) => {
    try {
      await fetch(`${CSET_URL}api/questionlist`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((data) => {
          if (reqdata) {
            setRequirementCount(data.requirementCount);
            if (data.applicationMode === "Q") {
              setQuestionsCount(getTotalQuestions(data));
            } else {
              setLoading(false);
            }
          }
          if (data.questionCount === 0) {
            setShowQuestionMode(false);
          }
          if (data.requirementCount === 0) {
            setShowRequirementMode(false);
          }
          setModeType(data.applicationMode);
          param["activeMode"] = data.applicationMode;
        })
        .catch((err:any) => {
          sentry_error_catch(err,setLoading,setFormState)
        });
    } catch (err:any) {
        sentry_error_catch(err,setLoading,setFormState)

    }
  };

  const getTotalQuestions = (arr: any) => {
    let TotalCount: any = 0;
    if (arr.categories !== undefined) {
      const tempArray: any = [];
      arr.categories.forEach((element: any, index: any) => {
        element.subCategories.forEach((ques: any) => {
          tempArray.push(ques.questions.length);
        });
      });
      for (let i = 0; i < tempArray.length; ++i) {
        TotalCount += tempArray[i];
      }
    }
    setLoading(false);
    return TotalCount;
  };

  const getStandardSelection = async () => {
    try {
      await fetch(`${CSET_URL}api/standards`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((data) => {
          setStandardObj(data.categories);
          restoreData(data);
          getQuestionList(data);
        })
        .catch((error: any) => {
          setLoading(false);
          handleError(error);
        });
    } catch (err:any) {
      sentry_error_catch(err,setLoading,setFormState)
    }
  };

  const array1: any = [];
  const restoreData = (data: any) => {
    data.categories.forEach((element: any) => {
      element.standards.forEach((obj: any) => {
        if (obj.selected === true) {
          array1.push(obj.code);
          setCheckedValue(array1);
        }
      });
    });
  };

  const handleBack = () => {
    setStepper(
      client,
      stepperObject.securityAssuranceLevel.name,
      stepperObject.securityAssuranceLevel.value
    );
    history(routeConstant.SECURITY_ASSURANCE_LEVEL, { state: { param } });
  };

  const handleSubmit = async () => {
    if (questionsCount !== 0 || (requirementCount !== 0 && modeType !== "")) {
      param["activeMode"] = modeType;
      handleNext();
    }
    if (questionsCount === 0 && requirementCount === 0) {
      window.scrollTo(0, 0);
      setFormState((formState) => ({
        ...formState,
        isSuccess: false,
        isUpdate: false,
        isDelete: false,
        isFailed: true,
        errMessage: " Please select cybersecurity standards",
      }));
    } else if (modeType === "") {
      setFormState((formState) => ({
        ...formState,
        isSuccess: false,
        isUpdate: false,
        isDelete: false,
        isFailed: true,
        errMessage: " Please select Mode Type",
      }));
    }
  };

  const handleNext = () => {
    if (authToken) {
      auditTrail({
        variables: {
          auditTrailInput: {
            individual: param.individualId,
            organization: param.partnerOrganizationId,
            action: {
              message: "Clicked on Next Button to Redirect to Questions Page.",
              data: null,
            },
            flowtype: param.flowType.type
              ? param.flowType.type
              : param.flowType,
            page: "Cybersecurity Standards Selection Page",
            client: param.clientOrgId,
            cset_assessment: param.ccAssessmentInfo.id,
          },
        },
      })
        .then((res: any) => {
          setStepper(
            client,
            stepperObject.questions.name,
            stepperObject.questions.value
          );
          history(routeConstant.QUESTIONS, { state: { param } });
        })
        .catch((err: any) => {
          sentry_error_catch(err,setLoading,setFormState)
        });
    } else {
      logout();
    }
  };

  const handleCancel = () => {
    const val: any = {
      clientInfo: param.clientInfo,
      csetUserInfo: param.csetUserInfo,
      pgPartnerId: param.pgPartnerId,
      pgPartnerUserId: param.pgPartnerUserId,
    };
    updateAssessment({
      variables: {
        id: param.ccAssessmentInfo.id,
        assessment_type: param.ccAssessmentInfo.assessment_type,
        is_editing_assessment: "0"
      },
    }).then((res:any) => {
      history(routeConstant.ASSESSMENT_LIST, { state: { val } });
    })
  };

  const handleError = (err:any) => {
    setLoading(false);
    setFormState((formState:any) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: true,
      errMessage: ERROR_MESSAGE,
    }))
    Sentry.captureException(err);
  };

  let selectedArray: any = [];
  let quesObj: any = [];
  const handleCheckElement = (event: any) => {
    handleAlertClose();
    quesObj = standardObj;
    const val = event.target.value;
    selectedArray = checkedValue;
    if (standardObj) {
      standardObj.forEach((element: any, index: any) => {
        element.standards.forEach((obj: any, i: any) => {
          if (obj.code === val) {
            if (selectedArray.includes(val)) {
              const position = selectedArray.indexOf(val);
              if (position >= 0) {
                selectedArray.splice(position, 1);
              }
              quesObj[index].standards[i].selected = false;
            } else {
              selectedArray.push(val);
              quesObj[index].standards[i].selected = true;
            }
            setStandardObj(quesObj);
          }
        });
      });
    }
    setStandardArray(selectedArray);
    postStandardSelection();
  };

  const postStandardSelection = async () => {
    await fetch(`${CSET_URL}api/standard`, {
      method: "POST",
      headers: headerObj,
      body: JSON.stringify(selectedArray),
    })
      .then((response) => response.json())
      .then((data) => {
        getQuestionList(data);
      })
      .catch((error: any) => {
        handleError(error);
      });
  };

  const callingFromTopStepper = (event: any) => {
    setStepperObj(event);
    handleSubmit();
  };

  const handleModeType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const type = (event.target as HTMLInputElement).value;
    setModeType(type);
    setMode(type);
    if (type === "Q") {
      setRequirementCount("0");
    }
    if (type === "R") {
      setQuestionsCount("0");
    }
  };

  const closeDialogBox = () => {
    try{
      setOpenDialogBox(false);
    }catch(err: any){
      showBoundary(err)
    }
  };

  const cancelHandler = () => {
    try{
      setOpenDialogBox(true);
    }catch(err: any){
      showBoundary(err)
    }
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Typography component="h5" variant="h1">
        Risk Assessment for {param ? param.clientInfo.name : null}
      </Typography>
      <DialogBox
        open={openDialogBox}
        handleOk={handleCancel}
        handleCancel={closeDialogBox}
        buttonOk={"Yes"}
        buttonCancel={"No"}
        classes={{
          root: styles.MainOfficeDialogRoot,
          container: styles.MainOfficeDialogboxContainer,
          paper: styles.MainOfficeDialogboxPaper,
          scrollPaper: styles.MainOfficeScrollPaper,
        }}>
        <div className={styles.DialogBoxTitle}>
          <Typography component="h1" variant="h1">
            Please Confirm
          </Typography>
        </div>
        <div className={styles.DialogBoxContext}>
          <p>
          {CANCEL_MSG}{" "}
            {/* {param.assessmentInfo?.assessmentName}? */}
          </p>
        </div>
      </DialogBox>
      <Grid container spacing={0}>
        <Grid item xs={12} className={styles.FooterGridWrap}>
          <div className={styles.topstepperWrap}>
            <div className={styles.prevBtn}>
              <Button
                id="previous-button"
                variant="contained"
                color="primary"
                onClick={handleBack}
              >
                {"<< Previous"}
              </Button>
            </div>
            <div className={styles.topstepperButonsWrap}>
              <TopStepper
                param={param}
                handleSave={callingFromTopStepper}
                obj={stepperObj}
                submitDisabled={false}
              />
            </div>
            <div className={styles.nextBtn}>
              <Button
                id="next-button"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                {"Next >>"}
              </Button>
            </div>
          </div>
          {!is_link_assessment && (<div className={styles.crossBtn}>
            <Button
              id="cancel-button"
              variant="contained"
              color="primary"
              onClick={cancelHandler}
            >
              {"X"}
            </Button>
            <span className={styles.cancleTooltip}>Cancel</span>
          </div>)}
        </Grid>
      </Grid>
      <Grid container>
        {loading ? <SimpleBackdrop /> : null}
        <Grid item xs={12}>
        <Grid item xs={12}>
              {formState.isFailed ? (
                <Alert
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={handleAlertClose}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {formState.errMessage}
                </Alert>
              ) : null}
            </Grid>
          <Paper className={styles.paper}>
            <span className={styles.CompanyFormHead}>{"Mode Selection"}</span>
            <div className={styles.Companyrequireblock}>
              <div className={styles.CompanyrequireblockWrap}>
                {modeType === "R" ? (
                  <>
                    <div className={styles.Companyrequire}>Requirement:</div>
                    <div className={styles.Companyrequire}>
                      <span className={styles.Companyrequirenos}>
                        {" "}
                        {requirementCount}
                      </span>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className={styles.CompanyrequireblockWrap}>
                {modeType === "Q" ? (
                  <>
                    <div className={styles.Companyrequire}>Questions:</div>
                    <div className={styles.Companyrequire}>
                      <span className={styles.Companyrequirenos}>
                        {questionsCount}
                      </span>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <FormControl component="fieldset">
              <span className={styles.CompanyFormHead}>{"Select Mode"}</span>
              <RadioGroup
                id="assessment-type"
                row
                aria-label="Assessment Type"
                name="Assessment Type"
                value={modeType}
                onChange={handleModeType}
              >
                {showQuestionMode && (
                  <FormControlLabel
                    value="Q"
                    control={<Radio className={styles.CompanySelect} />}
                    label="Questions Mode"
                  />
                )}
                {showRequirementMode && (
                  <FormControlLabel
                    value="R"
                    control={<Radio className={styles.CompanySelect} />}
                    label="Requirement Mode"
                  />
                )}
              </RadioGroup>
            </FormControl>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Standards;
