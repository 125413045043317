import { gql } from "@apollo/client";

export const CREATE_CSET_ASSESSMENT = gql`
  mutation CreateAssessment(
    $contact_id: ID!
    $assessment_id: Int!
    $assessment_type: ENUM_CSETASSESSMENT_ASSESSMENT_TYPE!
    $cset_firm_id: ID
    $assetValue: String
    $stateProvRegion: String
    $cityOrSiteName: String
    $user_id: ID
    $facility_name: String
    $cset_assessment_type:ID
  ) {
    createCsetAssessment(
      input: {
        data: {
          contact_id: $contact_id
          assessment_id: $assessment_id
          assessment_type: $assessment_type
          cset_firm_id: $cset_firm_id
          assetValue: $assetValue
          stateProvRegion: $stateProvRegion
          cityOrSiteName: $cityOrSiteName
          user_id: $user_id
          facility_name:$facility_name
          cset_assessment_type:$cset_assessment_type
        }
      }
    ) {
      csetAssessment {
        id
        contact_id {
          id
          email
        }
        user_id {
          id
          username
        }
        assessment_id
        assessment_type
        assetValue
        cityOrSiteName
        stateProvRegion
        facility_name
        cset_firm_id {
          id
          name
          firm_size
          sal
          sector {
            name
            sector_id
          }
          industry {
            name
            industry_id
            sector_id
          }
          relative_effort {
            demographics_id
            description
            size
          }
          cset_standards {
            name
            code
          }
        }
      }
    }
  }
`;

export const UPDATE_CSET_ASSESSMENT = gql`
  mutation UpdateAssessment(
    $id: ID!
    $cset_firm_id: ID
    $assessment_type: ENUM_CSETASSESSMENT_ASSESSMENT_TYPE!
    $allow_download: Boolean
    $assessment_name: String
    $assessment_document: [ID]
    $maturity_level: String
    $assetValue: String
    $report_generation: Boolean
    $cityOrSiteName: String
    $stateProvRegion: String
    $facilitator:String
    $criticalPointOfContact:String
    $deleteData: JSON
    $cset_assessment_type:ID
    $is_editing_assessment:String
  ) {
    updateCsetAssessment(
      input: {
        where: { id: $id }
        data: {
          assessment_type: $assessment_type
          cset_firm_id: $cset_firm_id
          allow_download: $allow_download
          assessment_name: $assessment_name
          assessment_document: $assessment_document
          maturityLevel: $maturity_level
          assetValue: $assetValue
          report_generation: $report_generation
          cityOrSiteName: $cityOrSiteName
          stateProvRegion: $stateProvRegion
          facilitator: $facilitator
          critical_point_of_contact: $criticalPointOfContact
          deleteData: $deleteData
          cset_assessment_type:$cset_assessment_type
          is_editing_assessment:$is_editing_assessment
        }
      }
    ) {
      csetAssessment {
        id
        contact_id {
          id
          email
        }
        is_editing_assessment
        assessment_id
        assessment_name
        assessment_type
        assetValue
        cityOrSiteName
        stateProvRegion
        report_generation
        facilitator
        critical_point_of_contact
        assessment_document {
          id
          url
        }
        maturityLevel
        cset_firm_id {
          id
          name
          firm_size
          sal
          sector {
            name
            sector_id
          }
          industry {
            name
            industry_id
            sector_id
          }
          relative_effort {
            demographics_id
            description
            size
          }
          cset_standards {
            name
            code
          }
        }
      }
    }
  }
`;

export const DELETE_CSET_ASSESSMENT = gql`
  mutation DeleteAssessment($id: ID!) {
    deleteCsetAssessment(input: { where: { id: $id } }) {
      csetAssessment {
        id
        assessment_id
      }
    }
  }
`;

export const CLONE_ASSESSMENT = gql`
mutation createAssessmentCopy($data:JSON){
 createAssessmentCopy(input:$data){
    message
  }
}
`
