import React, { useState, useMemo, useEffect } from "react";
import { Routes, Navigate, Route, useLocation } from "react-router-dom";
import { SessionContext } from "./context/session";
import styles from "./containers/CSet/Assessment/Assessment.module.css";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import gqlClient from "./config/apolloclient";
import { ErrorHandler } from "./containers/ErrorHandler/ErrorHandler";
import { AuthRoutes } from "./AuthRoutes";
import { ThemeProvider } from "@mui/material";
import theme from "./theme/";
import logout from "./containers/Auth/Logout/Logout";
import { CompanyUser } from "./common/Roles";
import Cookies from "js-cookie";
import Login from "./containers/Auth/Login/login";
import LogoutPage from "./containers/Auth/LogoutPage/LogoutPage";
import { useIdleTimer } from "react-idle-timer";
import { AdminRoutes } from "./AdminRoutes";
import Alert from "./components/UI/Alert/Alert";
import { DialogBox } from "./components/UI/DialogBox/DialogBox";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Input from "./components/UI/Form/Input/Input";
import SimpleBackdrop from "./components/UI/Layout/Backdrop/Backdrop";
import { VALIDATE_ASSESSMENT_LINK } from "./graphql/queries/CsetAssessmentLink";
import { useLazyQuery,useMutation} from "@apollo/client";
import  OpenReplay  from '@openreplay/tracker';
import * as Sentry from "@sentry/react";
import { OR_ENABLE, OR_INGESTPOINT, OR_PROJECT_KEY } from "./config";
import { ERROR_MESSAGE,IS_EDIT_ASSESSMENT } from "./common/MessageConstants";
import { HISTORY } from "./graphql/mutations/AuditTrail";

function App() {
  const loc = useLocation();
  const urlParameter = loc.pathname.split('/').slice(2);
  const PGsession = Cookies.getJSON("ra_session");
  const PGuserCookie = Cookies.getJSON("ra_user");
  const PGcontact = Cookies.getJSON("ra_contact");
  const PGuserRole = Cookies.get("is_pa") || "no";
  const [password,setPassword] = useState("");
  const [showAssessmentAlertBox,setShowAssessmentAlertBox] = useState(false);
  const [showIsEditingDialogBox,setShowIsEditingDialogBox] = useState(false);
  const [auditTrail] = useMutation(HISTORY);
  const [validate_assessment,{data:validate_ass}] = useLazyQuery(VALIDATE_ASSESSMENT_LINK);
  const [showLinkPassword,setShowLinkPassword] = useState(loc.pathname.split('/').slice(2).length !== 0);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    isCreateLinkFailed: false,
    errMessage: "",
  });
  const passwordChangeHandler = (event:any) => {
    setPassword(event.target.value);
  }
  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      isCreateLinkFailed : false,
      errMessage: ``,
    }));
  }
  let isEditAssessmentAlertBox:any = (
    <DialogBox
      open={showIsEditingDialogBox}
      handleOk={() => {
        setShowIsEditingDialogBox(false);
      }}
      buttonOk={"Cancel"}
      classes={{
        root: styles.MainOfficeDialogRoot,
        container: styles.MainOfficeDialogboxContainer,
        paper: styles.MainOfficeMailDialogboxPaper,
        scrollPaper: styles.MainOfficeScrollPaper,
      }}
      skipCancel = {true}
    >
      {false ? <SimpleBackdrop /> : null}
      <div className={styles.DialogBoxTitle}>
        <Typography component="h1" variant="h1">
          {"Alert Box"}
        </Typography>
      </div>
      <div className={styles.DialogBoxContext}>
       <h3 className={`${styles.AlertBoxContent}`}>{IS_EDIT_ASSESSMENT}</h3>
      </div>
    </DialogBox>
   )
  let assessmentAlertBox:any = (
    <DialogBox
      open={showAssessmentAlertBox}
      handleOk={() => {
        setShowAssessmentAlertBox(false);
        assessmentAlertBox = ""
      }}
      buttonOk={"Cancel"}
      classes={{
        root: styles.MainOfficeDialogRoot,
        container: styles.MainOfficeDialogboxContainer,
        paper: styles.MainOfficeMailDialogboxPaper,
        scrollPaper: styles.MainOfficeScrollPaper,
      }}
      skipCancel = {true}
    >
      {false ? <SimpleBackdrop /> : null}
      <div className={styles.DialogBoxTitle}>
        <Typography component="h1" variant="h1">
          {"Alert Box"}
        </Typography>
      </div>
      <div className={styles.DialogBoxContext}>
       <h3 className={`${styles.AlertBoxContent}`}>{`The assessment you are attempting to access has been completed.`}</h3>
      </div>
    </DialogBox>
   )
  const checkLinkValidate = () => {
    console.log("urlParameter",urlParameter,password);
    validate_assessment({
      variables:{
        input:{
            "assessment_id": urlParameter[1],
            "password": password,
            "token": urlParameter[0],
            "link":  ``
        }
      },
      context :{
        headers: {
          'Authorization':  'Bearer' + " " + urlParameter[0] 
        },
      }
    }).then((data:any) => {
      console.log("data",data);
      if(data?.data?.SharedLinkValidation?.message === "Link Validated Successfully."){
        auditTrail({
          variables: {
            auditTrailInput: {
              individual: data.data.SharedLinkValidation.ra_validation_data[0].assessment_data.individualId,
              organization: data.data.SharedLinkValidation.ra_validation_data[0].assessment_data.partnerOrganizationId,
              action: {
                message: `Link authenticate successfully and User used the Link to edit the assessment.`,
                data: data.data.SharedLinkValidation.ra_validation_data[0]
              },
              flowtype: null,
              page: "Shared Link Popup",
              cset_assessment: urlParameter[1],
              client: data.data.SharedLinkValidation.ra_validation_data[0].assessment_data.clientOrgId,
            },
          },
        }).catch((error: any) => {
            console.log("Error :-", error);
        });
        setShowLinkPassword(false); 
        const obj = data.data.SharedLinkValidation.ra_validation_data[0];
        sessionStorage.setItem("ra_contact", JSON.stringify(obj.ra_contact));
        sessionStorage.setItem("is_pa", obj.is_pa);
        sessionStorage.setItem("ra_user", JSON.stringify(obj.ra_user));
        sessionStorage.setItem("ra_session", urlParameter[0]);
        sessionStorage.setItem("ra_app", "RA360");
        sessionStorage.setItem("ra_token",obj.ra_token);
        sessionStorage.setItem("is_link_authenticated","true")
        sessionStorage.setItem("param",JSON.stringify(obj.assessment_data));
        window.location.reload();
      }else if(data?.data?.SharedLinkValidation?.message === "The assessment is currently being editing."){
        setShowLinkPassword(false);
        setShowIsEditingDialogBox(true);
      }else if(data?.data?.SharedLinkValidation?.message === "Assessment is Completed."){
       setShowLinkPassword(false);
       setShowAssessmentAlertBox(true);
      }else if((data?.errors && data?.errors[0]?.message === "Invalid token.") || (data?.data?.SharedLinkValidation?.message === "Link has Expired.")){
        setFormState((formState) => ({
          ...formState,
          isSuccess: false,
          isUpdate: false,
          isDelete: false,
          isFailed: false,
          isCreateLinkFailed : true,
          errMessage: `The link is no longer valid. Please reach out to Partner Support for assistance.`,
        }));
      }else{
        setFormState((formState) => ({
          ...formState,
          isSuccess: false,
          isUpdate: false,
          isDelete: false,
          isFailed: false,
          isCreateLinkFailed : true,
          errMessage: `${data?.data?.SharedLinkValidation?.message}`,
        }));
      }
    }).catch((err:any) => {
       console.log("err",err);
       Sentry.captureException(err)
       setFormState((formState) => ({
        ...formState,
        isSuccess: false,
        isUpdate: false,
        isDelete: false,
        isFailed: false,
        isCreateLinkFailed : true,
        errMessage: ERROR_MESSAGE,
      }));
    })
  }
  let DialogBoxContent;
  console.log("urlParameter",urlParameter);
  if(urlParameter.length > 0 && 
    sessionStorage.getItem("ra_session") == null &&
    sessionStorage.getItem("ra_user") == null &&
    sessionStorage.getItem("ra_contact") == null
  ){
    DialogBoxContent = (<DialogBox
      open={showLinkPassword}
      handleOk={checkLinkValidate}
      handleCancel={() => {
        setShowLinkPassword(false);
        setPassword("");
      }}
      buttonOk={"Submit"}
      buttonCancel={"Cancel"}
      classes={{
        root: styles.MainOfficeDialogRoot,
        container: styles.MainOfficeDialogboxContainer,
        paper: styles.MainOfficeMailDialogboxPaper,
        scrollPaper: styles.MainOfficeScrollPaper,
      }}
      disabled={password.length <= 0}
    >
      {false ? <SimpleBackdrop /> : null}
      <div className={styles.DialogBoxTitle}>
        <Typography component="h1" variant="h1">
          {"Password Box"}
        </Typography>
      </div>
      <div className={styles.DialogBoxContext}>
      {formState.isCreateLinkFailed ? (
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleAlertClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <strong>{formState.errMessage}</strong>
        </Alert>
      ) : null}
        <Grid container>
          <Grid item xs={4} classes={{ item: styles.MuiGridItem }}>
            <Typography component="h3" variant="h3"> Password :</Typography>
          </Grid>
          <Grid
            item
            xs={8}
            classes={{ item: styles.ReactInput1 }}
          >
            <Input
              id="assessmentLinkSendMail"
              name="assessmentLinkSendMail" 
              value = {password}
              Autocomplete="off"
              onChange = {passwordChangeHandler}
            >
              Password
            </Input>
          </Grid>
        </Grid>
      </div>
    </DialogBox>)
  }
  if (
    PGsession &&
    PGuserCookie &&
    PGcontact !== undefined &&
    PGuserRole &&
    urlParameter.length === 0 &&
    sessionStorage.getItem("ra_user") == null &&
    sessionStorage.getItem("ra_contact") == null
  ) {
    sessionStorage.setItem("ra_contact", JSON.stringify(PGcontact));
    sessionStorage.setItem("is_pa", PGuserRole);
    sessionStorage.setItem("ra_user", JSON.stringify(PGuserCookie));
    // sessionStorage.setItem("ra_session", PGsession);
    sessionStorage.setItem("ra_app", "RA360");
  }
  const RAsession: any = sessionStorage.getItem("ra_session");
  const RAuser: any = sessionStorage.getItem("ra_user");
  const session = RAsession;
  const user = JSON.parse(RAuser);
  const [authenticated, setAuthenticated] = useState(session ? true : false);
  const values = useMemo(
    () => ({
      authenticated,
      setAuthenticated: (value: any) => {
        setAuthenticated(value);
      },
    }),
    []
  );
  const accessToken = session ? session : null;
  const client = authenticated ? gqlClient(accessToken) : gqlClient(null);
  let routes;
  if (authenticated) {
    if (user?.user.role.name === CompanyUser) {
      routes = <div>{AuthRoutes}</div>;
    } else if (user?.user.role.name === "Administrator") {
      routes = <div>{AdminRoutes}</div>;
    }
  } else if (!authenticated) {
    routes = (
      <Routes>
        <Route path={"/login"} element={<Login />} />
        <Route path="/logout" element={<LogoutPage />} />
        {/* <Route path={"/thankyou"} exact element={DefaultBanner} /> */}
        {urlParameter.length === 0 ? <Route path="*" element={<Navigate to="/logout" />} /> : null}
      </Routes>
    );
  }

  const onIdle = () => {
    if (authenticated) {
      setTimeout(function () {
        logout();
        window.location.replace("/logout");
      }, 1000 * 60 * 2);
    }
  };

  const IdleTimer = useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * 60 * 4,
    promptTimeout: 0,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false,
  });

  // -----------OPENREPLAY SETUP----------
  

  useEffect(()=>{
      if(OR_ENABLE===true){
        if(user?.user?.username !== undefined){
          const tracker = new OpenReplay({
            projectKey: OR_PROJECT_KEY,
            ingestPoint: OR_INGESTPOINT,
            // __DISABLE_SECURE_MODE:true,        //for testing locally
            onStart: ({ sessionToken, sessionID }) => {
                Sentry.setTag("openReplaySessionURL", tracker.getSessionURL());
              },
          });
          if(!(tracker.isActive())){
            tracker.start({
              userID:user?.user.username,
            })
            .then(() => {
                  console.log('tracker started successfully.');
             })
             .catch((error:any) => {
                  Sentry.captureException(error)
                  console.error('tracker failed to start:', error);
              });
          }
        }else{
          console.log('user id not present')
        }
      }
  },[])
  
  return (
    <SessionContext.Provider value={values}>
        <ThemeProvider theme={theme}>
          <ErrorHandler />
          {showIsEditingDialogBox ? isEditAssessmentAlertBox : null}
          {showAssessmentAlertBox ? assessmentAlertBox : null}
          {urlParameter.length > 0 && sessionStorage.getItem("is_link_authenticated") !== 'true' ? DialogBoxContent : null}
          {urlParameter.length <= 0 || accessToken ? routes : null}
        </ThemeProvider>
    </SessionContext.Provider>
  );
}

export default App;
