import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { sentry_error_catch } from "../../common/sentry_error_catch";
import CssBaseline from "@mui/material/CssBaseline";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import styles from "./SampleReports.module.css";
import { Button } from "../../components/UI/Form/Button/Button";
import * as routeConstant from "../../common/RouteConstants";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SimpleBackdrop from "../../components/UI/Layout/Backdrop/Backdrop";
import GetAppIcon from "@mui/icons-material/GetApp";
import { saveAs } from "file-saver";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import Badge from "@mui/material/Badge";
import { GET_CSET_ASSESSMENT_TYPE } from "../../graphql/queries/CSETAssessment";

export const SampleReports: React.FC = (props: any) => {
  const [showBackdrop, setShowBackdrop] = useState(true);
  const history = useNavigate();
  const [selectedTab, setSelectedTab] = useState("Quick Assessments");
  const [csetAssessmentType, setCsetAssessmentType] = useState<any>([]);
  const [searchAssessment, setSearchAssessment] = useState<any>("");
  const [searchAssessmentType, setSearchAssessmentType] = useState<any>([]);
  const [quickAssessment, setQuickAssessment] = useState<any>([]);
  const [advancedAssessment, setAdvancedAssessment] = useState<any>([]);
  const [badgeCount, setBadgeCount] = useState<number>(0);
  const [getCsetAssessmentType] = useLazyQuery(GET_CSET_ASSESSMENT_TYPE);
  const [formStates, setFormStates] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });

  const removeDuplicates = (arrayWithDuplicates :any) => {
    const uniqueArray = arrayWithDuplicates.filter((value:any, index:any, self:any) =>
      index === self.findIndex((obj:any) => obj.name === value.name)
    );
    return uniqueArray
  }

  useEffect(() => {
    setShowBackdrop(true)
    getCsetAssessmentType().then((data: any) => {
      const groupByAssessment: any = {};
      const advancedAssessmentArray: any = [];
      const quickAssessmentArray: any = [];
      data.data.csetAssessmentTypes.forEach((obj: any) => {
        if (obj.group_Title === "Advanced Assessment") {
          advancedAssessmentArray.push(obj);
        } else {
          quickAssessmentArray.push(obj);
        }
      });
      const uniqueAdvancedAssessmentArray = removeDuplicates(advancedAssessmentArray);
      groupByAssessment["Quick Assessments"] = quickAssessmentArray;
      groupByAssessment["Advanced Assessments"] = uniqueAdvancedAssessmentArray;
      setCsetAssessmentType(groupByAssessment);
      setSearchAssessmentType(groupByAssessment);
      console.log("uniqueAdvancedAssessmentArray",uniqueAdvancedAssessmentArray.length)
      handleChange({}, "Quick Assessments", groupByAssessment);
      setShowBackdrop(false);
    }).catch((err: any) => {
      sentry_error_catch(err,setShowBackdrop,setFormStates)
    });
  }, []);
    
  const backToList = () => {
    try{   
      history(routeConstant.CLIENT);
    }catch(err){
    //   showBoundary(err);
    }
  }

  const sampleReportDownloadHandler = (assessmentType:string,assessmentName:string) => {
    setShowBackdrop(true);
    let DocUrl = "" ;
    const invalidChars = /[\\/.:*?"<>|]/g;
    const newAssessmentName = assessmentName.replace(invalidChars, '');
    let fileName = 'Sample ' + newAssessmentName;
    console.log("assessmentType",newAssessmentName)
    console.log("assessmentName",assessmentName)
    if(assessmentType === "Quick Assessments"){
       DocUrl = `${process.env.PUBLIC_URL}/sample_reports/Quick_Assessment/${assessmentName}/Report.zip`;
    }else{
       DocUrl = `${process.env.PUBLIC_URL}/sample_reports/Advanced_Assessment/${newAssessmentName.trim()}/Report.zip`;
    }
    console.log("Docurl",DocUrl)
    fetch(DocUrl, {
      method: "GET",
    })
      .then((response: any) => {
        response.blob().then((blobData: any) => {
          saveAs(blobData,fileName);
          setShowBackdrop(false)
        });
      })
      .catch((err:any) => {
        sentry_error_catch(err,setShowBackdrop,setFormStates)
      });
  }

  const onSearchAssessmentHandler = () => {
    try{
    clearStateVariable();
    let dataObj: any = {};
    let QuickAssObj: any = {};
    let AdvancedAssObj: any = {};

    if (searchAssessment !== "") {
      Object.keys(searchAssessmentType).forEach((grp: any) => {
        const filterArray = searchAssessmentType[grp].filter((assType: any) =>
          assType.name.toLowerCase().includes(searchAssessment.toLowerCase())
        );
        if (filterArray.length > 0) {
          dataObj[grp] = filterArray;
        }
        if (grp === "Quick Assessments") {
          QuickAssObj["Quick Assessments"] = filterArray;
        }
        if (grp === "Advanced Assessments") {
          AdvancedAssObj["Advanced Assessments"] = filterArray;
        }
      });
      if (Object.keys(dataObj).length == 1) {
        if (Object.keys(dataObj)[0] === "Advanced Assessments") {
          setQuickAssessment([]);
        } else {
          setAdvancedAssessment([]);
        }
        setSelectedTab(Object.keys(dataObj)[0]);
      } else if (Object.keys(dataObj).length == 2) {
        setSelectedTab("Advanced Assessments");
        setBadgeCount(dataObj["Quick Assessments"].length);
        setAdvancedAssessment(AdvancedAssObj);
        setQuickAssessment(QuickAssObj);
        setCsetAssessmentType(AdvancedAssObj);
        return;
      }
      setCsetAssessmentType(dataObj);
    } else {
      clearStateVariable();
      handleChange({}, "Quick Assessments", searchAssessmentType, true);
    }
   }catch(err){
    // showBoundary(err);
   }
  };

  const handleChange = (
    event: any,
    newValue: string,
    assObj?: any,
    isReset?: any
  ) => {
    try{
    if (
      badgeCount &&
      badgeCount > 0 &&
      newValue === "Quick Assessments" &&
      !isReset
    ) {
      setSelectedTab("Quick Assessments");
      setCsetAssessmentType(quickAssessment);
    } else if (
      badgeCount &&
      badgeCount > 0 &&
      selectedTab !== newValue &&
      !isReset
    ) {
      setSelectedTab(newValue);
      setCsetAssessmentType(advancedAssessment);
    } else {
      if (
        selectedTab !== newValue &&
        (quickAssessment || advancedAssessment) &&
        badgeCount === 0 &&
        !isReset
      ) {
        clearStateVariable();
        setSearchAssessment("");
      }
      setSelectedTab(newValue);
      const tabObj: any = {};
      let tabAssessmentArray: any = [];
      if (newValue !== "") {
        if (assObj && Object.keys(assObj).length > 0) {
          tabAssessmentArray = assObj[newValue];
        } else {
          tabAssessmentArray = searchAssessmentType[newValue];
        }
        tabObj[newValue] = tabAssessmentArray;
      }
      setCsetAssessmentType(tabObj);
    }
   }catch(err){
    // showBoundary(err);
   }
  };

  const searchChangeHandler = (event: any) => {
    const searchText = event.target.value.trim();
    setSearchAssessment(searchText);
  };

  const onEnterSearchHandler = (ev: any) => {
    if (ev.key === "Enter") {
      onSearchAssessmentHandler();
    }
  };

  const clearStateVariable = () => {
    setBadgeCount(0);
    setAdvancedAssessment([]);
    setQuickAssessment([]);
    setSelectedTab("");
  };

  return (
    <React.Fragment>
      {showBackdrop ? <SimpleBackdrop /> : null}
      <CssBaseline />
      <Grid container>
        <Grid item xs={10} md={10} className={styles.FilterWrap} style={{paddingLeft:"8px"}}>
          <Typography component="h5" variant="h1">
            Sample Reports
          </Typography>
        </Grid>
        <Grid item xs={2} className={styles.FilterAddWrap}>
          <div className={styles.ButtonGroup2}>
            <Button
              id="cancel-button"
              className={styles.BackToButton}
              variant={"contained"}
              onClick={backToList}
              color="secondary"
              data-testid="cancel-button"
            >
              <img
                src={`${process.env.PUBLIC_URL}/icons/svg-icon/back-list.svg`}
                alt="user icon"
              />
              &nbsp; Back to List
            </Button>
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={8}>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            classes={{ flexContainer: styles.muiTabsFlexContainer }}
          >
            <Tab
              value="Quick Assessments"
              label={
                badgeCount && badgeCount > 0 ? (
                  <Badge
                    badgeContent={badgeCount}
                    color="secondary"
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    classes={{ badge: styles.batchStyle }}
                  >
                    Quick Assessments
                  </Badge>
                ) : (
                  "Quick Assessments"
                )
              }
              classes={{ selected: styles.selectedTab }}
            />
            <Tab
              value="Advanced Assessments"
              label="Advanced Assessments"
              classes={{ selected: styles.selectedTab }}
            />
          </Tabs>
        </Grid>
        <Grid item xs={4} className={styles.assessmentTypeSearchWrap}>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            onChange={searchChangeHandler}
            onKeyDown={onEnterSearchHandler}
            value={searchAssessment}
            autoComplete="off"
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  classes={{
                    positionStart: styles.MuiInputAdornmentPositionStart,
                  }}
                ></InputAdornment>
              ),
            }}
          />
          <Tooltip title="Search" placement="top">
            <div className={styles.crossBtn}>
              <Button
                id="cancel-button"
                variant="contained"
                color="primary"
                onClick={onSearchAssessmentHandler}
              >
                <SearchIcon />
              </Button>
            </div>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container spacing={4} className={styles.reportListText}>
        {csetAssessmentType[selectedTab] && csetAssessmentType[selectedTab].length > 0
        ? csetAssessmentType[selectedTab].map((assType: any, index: any) => {
          return (
            <Grid item xs={4}>
                <Grid container spacing={4}>
                  <Grid item xs={10} className={styles.assTypeName}>
                    {assType.name}
                  </Grid>
                  <Grid item xs={2}>
                    <GetAppIcon
                      style={{cursor:"pointer"}} 
                      onClick={() => {
                       sampleReportDownloadHandler(selectedTab,assType.name)
                      }} 
                    />
                  </Grid>
                </Grid>
            </Grid>
          );
        })
        : null}
      </Grid>
          
    </React.Fragment>)
}