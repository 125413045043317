import React, { useEffect, useState } from "react";
import styles from "./AssessmentType.module.css";
import CssBaseline from "@mui/material/CssBaseline";
import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import Tooltip from "@mui/material/Tooltip";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Badge from "@mui/material/Badge";
import Grid from "@mui/material/Grid";
import { Button } from "../../../components/UI/Form/Button/Button";
import { useNavigate, useLocation } from "react-router-dom";
import * as routeConstant from "../../../common/RouteConstants";
import SimpleBackdrop from "../../../components/UI/Layout/Backdrop/Backdrop";
import {
  CSET_URL,
  REACT_APP_PG_URL,
  PG_CHECK_SCAN_COUNT_API_KEY,
} from "../../../config/index";
import { DELETE_CSET_ASSESSMENT } from "../../../graphql/mutations/CSETAssessment";
import { GET_CSET_ASSESSMENT_TYPE } from "../../../graphql/queries/CSETAssessment";
import { DialogBox } from "../../../components/UI/DialogBox/DialogBox";
import logout from "../../Auth/Logout/Logout";
import IconButton from "@mui/material/IconButton";
import Alert from "../../../components/UI/Alert/Alert";
import CloseIcon from "@mui/icons-material/Close";
import * as msgConstants from "../../../common/MessageConstants";
import { HISTORY } from "../../../graphql/mutations/AuditTrail";
import { useLazyQuery, useMutation } from "@apollo/client";
import { sentry_error_catch } from "../../../common/sentry_error_catch";
import { useErrorBoundary } from "react-error-boundary";

export const AssessmentType: React.FC = (props: any) => {
  const location = useLocation();
  const authToken = sessionStorage.getItem("ra_token") || logout();
  const { showBoundary } = useErrorBoundary();
  const [pricing, setPricing] = useState<any>(null);
  const [param, setParam] = useState<any>(
    location?.state[Object.keys(location?.state)[0]]
  );
  const [price, setPrice] = useState<any>("");
  const [showBackdrop, setShowBackdrop] = useState(true);
  const [assessmentType, setAssessmentType] = useState<any>("");
  const [totalNumberScans, setTotalNumberScans] = useState<any>(0);
  const [openDialogBox, setOpenDialogBox] = useState<boolean>(false);
  const history = useNavigate();
  const [openPricingBox, setOpenPricingBox] = useState<boolean>(false);
  const [csetAssessmentType, setCsetAssessmentType] = useState<any>([]);
  const [searchAssessmentType, setSearchAssessmentType] = useState<any>([]);
  const [assessmentTitle, setAssessmentTitle] = useState<any>("");
  const [assessmentDescription, setAssessmentDescription] = useState<any>("");
  const [showViewDetails, setShowViewDetails] = useState<boolean>(false);
  const [searchAssessment, setSearchAssessment] = useState<any>("");
  const [formStates, setFormStates] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    isAssessmentType: false,
    errMessage: "",
  });
  const [selectedTab, setSelectedTab] = useState("Quick Assessments");
  const [badgeCount, setBadgeCount] = useState<number>(0);
  const [quickAssessment, setQuickAssessment] = useState<any>([]);
  const [advancedAssessment, setAdvancedAssessment] = useState<any>([]);

  const [auditTrail] = useMutation(HISTORY);
  const [deteleAssessement] = useMutation(DELETE_CSET_ASSESSMENT);
  const [getCsetAssessmentType] = useLazyQuery(GET_CSET_ASSESSMENT_TYPE);
  const firstClassIndex = [0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48];
  const lastClassIndex = [3, 7, 11, 15, 19, 23, 27, 31, 35, 39, 43, 47, 49];

  useEffect(() => {
    setParam(location?.state[Object.keys(location?.state)[0]]);
  }, []);

  useEffect(() => {
    setShowBackdrop(true);
    resetHandler();
    getCsetAssessmentType()
      .then((data: any) => {
        const groupByAssessment: any = {};
        const advancedAssessmentArray: any = [];
        const quickAssessmentArray: any = [];
        data.data.csetAssessmentTypes.forEach((obj: any) => {
          if (obj.group_Title === "Advanced Assessment") {
            advancedAssessmentArray.push(obj);
          } else {
            quickAssessmentArray.push(obj);
          }
        });

        groupByAssessment["Quick Assessments"] = quickAssessmentArray;
        groupByAssessment["Advanced Assessments"] = advancedAssessmentArray;
        setCsetAssessmentType(groupByAssessment);
        setSearchAssessmentType(groupByAssessment);
        handleChange({}, "Quick Assessments", groupByAssessment);
        setShowBackdrop(false);
      })
      .catch((err: any) => {
        sentry_error_catch(err,setShowBackdrop,setFormStates)
      });
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    if (param.hasOwnProperty("flowType")) {
      setAssessmentType(param.flowType.type);
    }
    // if (param.pgPartnerId !== undefined) {
    //   getAvailibityData();
    // }
    return () => controller.abort();
  }, [param]);

  useEffect(() => {
    const controller = new AbortController();
    if (param.pgPartnerId !== undefined) {
      getAvailibityData();
    }
    return () => controller.abort();
  },[param,selectedTab])

  useEffect(() => {
    if (param.hasOwnProperty("flowType") && pricing !== null) {
      setAssessmentType(param?.flowType.type);
      if (Object.keys(csetAssessmentType)[0] === "Quick Assessments") {
        setTotalNumberScans(
          pricing?.values?.CRR?.scan_available?.free +
            pricing?.values?.CRR?.scan_available?.credit
        );
        setPrice(pricing?.values?.CRR?.scan_available?.price);
      }

      if (Object.keys(csetAssessmentType)[0] === "Advanced Assessments") {
        setTotalNumberScans(
          pricing?.values?.RAA?.scan_available?.free +
            pricing?.values?.RAA?.scan_available?.credit
        );
        setPrice(pricing?.values?.RAA?.scan_available?.price);
      }
    }
  }, [pricing, csetAssessmentType]);

  const headerObj = {
    "Content-Type": msgConstants.CONTENT_TYPE,
    Authorization: authToken,
  };

  console.log("param", param);
  console.log("assessment type,pg partner Id",param.pgPartnerId);
  const handleSubmit = async (obj: any) => {
    try{
    const val = {
      assessmentInfo: {},
      ccAssessmentInfo: {},
      clientInfo: param.clientInfo,
      csetUserInfo: param.csetUserInfo,
      pgPartnerId: param.pgPartnerId,
      totalNumberScans,
      pgPartnerUserId: param.pgPartnerUserId,
      ScanPrice: price,
      flowType: { type: obj.assessment_type },
      individualId: param.individualId,
      partnerOrganizationId: param.partnerOrganizationId,
      clientOrgId: param.clientOrgId,
      assessmentGUID: obj.GUID,
      csetAssessmentTypeId: obj.id,
      groupTitle: obj.group_Title,
      assessmentDescription: obj.description,
      assessmentName: obj.name,
    };
    setParam(val);
    if (obj.assessment_type !== "") {
      auditTrail({
        variables: {
          auditTrailInput: {
            individual: param.individualId,
            organization: param.partnerOrganizationId,
            action: {
              message:
                "Clicked on Assessment Type to Redirect to Assessment Info Page",
              data: null,
            },
            flowtype: assessmentType,
            page: "Assessment Type Page",
            client: param.clientOrgId,
            cset_assessment: param?.ccAssessmentInfo?.id,
          },
        },
      }).then((res: any) => {
        if (obj.assessment_type === "CMMC2") {
          history(routeConstant.CMMC_TUTORIAL, { state: { val } });
        } else if (obj.assessment_type === "CRR") {
          history(routeConstant.CRR_TUTORIAL, { state: { val } });
        } else if (obj.assessment_type === "RRA") {
          history(routeConstant.RRA_TUTORIAL, { state: { val } });
        } else if (obj.assessment_type === "Custom") {
          history(routeConstant.STANDARD_TUTORIAL, { state: { val } });
        } else {
          history(routeConstant.ASSESSMENT_INFO, { state: { val } });
        }
      }).catch((err: any) => {
        sentry_error_catch(err,setShowBackdrop,setFormStates)
      });
    } else {
      setFormStates((formState) => ({
        ...formState,
        isSuccess: false,
        isUpdate: false,
        isDelete: false,
        isFailed: false,
        isAssessmentType: true,
        errMessage: "",
      }));
    }
    }catch(err){
      showBoundary(err);
    }
  };

  const handleAlertClose = () => {
    setFormStates((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      isAssessmentType: false,
      errMessage: "",
    }));
  };

  const NavTo = () => {
    const val: any = {
      clientInfo: param.clientInfo,
      csetUserInfo: param.csetUserInfo,
      pgPartnerId: param.pgPartnerId,
      pgPartnerUserId: param.pgPartnerUserId,
    };
    history(routeConstant.ASSESSMENT_LIST, { state: { val } });
  };

  const getAvailibityData = async () => {
    await fetch(
      `${REACT_APP_PG_URL}/ob360-scans/api/v1/check-scan-count/post`,
      {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*",
          "Access-Control-Allow-Headers": "*",
          "api-key": PG_CHECK_SCAN_COUNT_API_KEY,
          "Content-type": msgConstants.CONTENT_TYPE,
        },
        body: JSON.stringify({
          partner_id: param.pgPartnerId,
          // type: "RA",
          type: "RA360",
          sub_type: selectedTab === "Quick Assessments" ? "QA" : selectedTab === "Advanced Assessments" ? "AA" : ""
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setPricing(data);
        setShowBackdrop(false);
      })
      .catch((err: any) => {
        sentry_error_catch(err,setShowBackdrop,setFormStates)
      });
  };

  const closeDialogBox = () => {
    setShowBackdrop(false);
    setOpenDialogBox(false);
    setOpenPricingBox(false);
  };

  const confirmDelete = async () => {
    closeDialogBox();
    setShowBackdrop(true);
    try {
      await fetch(
        `${CSET_URL}api/contacts/validateremoval?assessmentId=${param.ccAssessmentInfo?.assessment_id}`,
        {
          method: "POST",
          headers: headerObj,
        }
      )
        .then((response) => response.json())
        .then((data) => {
          deleteAssessment();
        });
    } catch (e) {}
  };

  const deleteAssessment = async () => {
    try {
      await fetch(`${CSET_URL}api/contacts/remove`, {
        method: "POST",
        headers: headerObj,
        body: JSON.stringify({
          assessmentId: param.ccAssessmentInfo?.assessment_id,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          onDeteleAssessment();
        }).catch((err: any) => {
          sentry_error_catch(err,setShowBackdrop,setFormStates)
        });
    } catch (e) {
      NavTo();
    }
  };

  const viewDetailHandler = (title: any, description: any) => {
    setShowViewDetails(true);
    setAssessmentTitle(title);
    setAssessmentDescription(description);
  };
  const onDeteleAssessment = async () => {
    deteleAssessement({
      variables: { id: param.ccAssessmentInfo?.id },
    })
      .then((res) => {
        NavTo();
        setShowBackdrop(false);
      })
      .catch((err: any) => {
        sentry_error_catch(err,setShowBackdrop,setFormStates)
      });
  };
  const onSearchAssessmentHandler = () => {
    try{
    clearStateVariable();
    let dataObj: any = {};
    let QuickAssObj: any = {};
    let AdvancedAssObj: any = {};

    if (searchAssessment !== "") {
      Object.keys(searchAssessmentType).forEach((grp: any) => {
        const filterArray = searchAssessmentType[grp].filter((assType: any) =>
          assType.name.toLowerCase().includes(searchAssessment.toLowerCase())
        );
        if (filterArray.length > 0) {
          dataObj[grp] = filterArray;
        }
        if (grp === "Quick Assessments") {
          QuickAssObj["Quick Assessments"] = filterArray;
        }
        if (grp === "Advanced Assessments") {
          AdvancedAssObj["Advanced Assessments"] = filterArray;
        }
      });
      if (Object.keys(dataObj).length == 1) {
        if (Object.keys(dataObj)[0] === "Advanced Assessments") {
          setQuickAssessment([]);
        } else {
          setAdvancedAssessment([]);
        }
        setSelectedTab(Object.keys(dataObj)[0]);
      } else if (Object.keys(dataObj).length == 2) {
        setSelectedTab("Advanced Assessments");
        setBadgeCount(dataObj["Quick Assessments"].length);
        setAdvancedAssessment(AdvancedAssObj);
        setQuickAssessment(QuickAssObj);
        setCsetAssessmentType(AdvancedAssObj);
        return;
      }
      setCsetAssessmentType(dataObj);
    } else {
      clearStateVariable();
      handleChange({}, "Quick Assessments", searchAssessmentType, true);
    }
   }catch(err){
    showBoundary(err);
   }
  };
  const searchChangeHandler = (event: any) => {
    const searchText = event.target.value.trim();
    setSearchAssessment(searchText);
  };
  const resetHandler = () => {
    if (searchAssessment !== "") {
      clearStateVariable();
      handleChange({}, "Quick Assessments", searchAssessmentType, true);
      setSearchAssessment("");
    }
  };
  const onEnterSearchHandler = (ev: any) => {
    if (ev.key === "Enter") {
      onSearchAssessmentHandler();
    }
  };
  const clearStateVariable = () => {
    setBadgeCount(0);
    setAdvancedAssessment([]);
    setQuickAssessment([]);
    setSelectedTab("");
  };
  const handleChange = (
    event: any,
    newValue: string,
    assObj?: any,
    isReset?: any
  ) => {
    try{
    if (
      badgeCount &&
      badgeCount > 0 &&
      newValue === "Quick Assessments" &&
      !isReset
    ) {
      setSelectedTab("Quick Assessments");
      setCsetAssessmentType(quickAssessment);
    } else if (
      badgeCount &&
      badgeCount > 0 &&
      selectedTab !== newValue &&
      !isReset
    ) {
      setSelectedTab(newValue);
      setCsetAssessmentType(advancedAssessment);
    } else {
      if (
        selectedTab !== newValue &&
        (quickAssessment || advancedAssessment) &&
        badgeCount === 0 &&
        !isReset
      ) {
        clearStateVariable();
        setSearchAssessment("");
      }
      setSelectedTab(newValue);
      const tabObj: any = {};
      let tabAssessmentArray: any = [];
      if (newValue !== "") {
        if (assObj && Object.keys(assObj).length > 0) {
          tabAssessmentArray = assObj[newValue];
        } else {
          tabAssessmentArray = searchAssessmentType[newValue];
        }
        tabObj[newValue] = tabAssessmentArray;
      }
      setCsetAssessmentType(tabObj);
    }
   }catch(err){
    showBoundary(err);
   }
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Grid container>
        <Grid item xs={10} className={styles.FilterWrap}>
          <Typography component="h5" variant="h1">
            Risk Assessment for {param ? param.clientInfo?.name : null}
          </Typography>
        </Grid>
        <Grid item xs={2} className={styles.FilterAddWrap}>
          <div className={styles.ButtonGroup2}>
            <Button
              id="cancel-button"
              className={styles.BackToButton}
              variant={"contained"}
              onClick={NavTo}
              color="secondary"
              data-testid="cancel-button"
            >
              <img
                src={`${process.env.PUBLIC_URL}/icons/svg-icon/back-list.svg`}
                alt="user icon"
              />
              &nbsp; Back to List
            </Button>
          </div>
        </Grid>
      </Grid>

      {openPricingBox ? (
        <DialogBox
          open={openPricingBox}
          handleOk={closeDialogBox}
          // handleCancel={closeDialogBox}
          skipCancel
          buttonOk={"Close"}
          // buttonCancel={"No"}
          classes={{
            root: styles.MainOfficeDialogRoot,
            container: styles.MainOfficeDialogboxContainer,
            paper: styles.PricingMainOfficeDialogboxPaper,
            scrollPaper: styles.MainOfficeScrollPaper,
          }}
        >
          <div className={styles.DialogBoxTitle}>
            <Typography component="h1" variant="h1">
              Pricing
            </Typography>
          </div>
          <div className="popup-body-wrap flex-container pricing-container">
            <div className="right-pricing-wrap">
              <div className="top-pricing-container">
                <div className="top-right-pricing-wrap detailed-tab">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Pricing per Scan</th>
                        <th>Essential</th>
                        <th>Professional</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr></tr>
                      <tr>
                        <td>Cyber Security Evaluation Tool </td>
                        <td>$99</td>
                        <td>$79</td>
                      </tr>
                      <tr>
                        <td>Cybersecurity Maturity Model Certification</td>
                        <td>$99</td>
                        <td>$79</td>
                      </tr>
                      <tr>
                        <td>Cyber Resilience Review</td>
                        <td>$19</td>
                        <td>$9</td>
                      </tr>
                      <tr>
                        <td>Ransomware Readiness Assessment</td>
                        <td>$19</td>
                        <td>$9</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </DialogBox>
      ) : (
        <></>
      )}
      {/* </Typography> */}
      <DialogBox
        open={openDialogBox}
        handleOk={confirmDelete}
        handleCancel={closeDialogBox}
        buttonOk={"Yes"}
        buttonCancel={"No"}
        classes={{
          root: styles.MainOfficeDialogRoot,
          container: styles.MainOfficeDialogboxContainer,
          paper: styles.MainOfficeDialogboxPaper,
          scrollPaper: styles.MainOfficeScrollPaper,
        }}
      >
        <div className={styles.DialogBoxTitle}>
          <Typography component="h1" variant="h1">
            Please Confirm
          </Typography>
        </div>
        <div className={styles.DialogBoxContext}>
          <p>
            Are you sure you want to remove{" "}
            {param?.assessmentInfo?.assessmentName}?
          </p>
        </div>
      </DialogBox>

      <DialogBox
        open={showViewDetails}
        skipCancel={true}
        handleOk={() => {
          setShowViewDetails(false);
        }}
        buttonOk={"Close"}
        classes={{
          root: styles.MainOfficeDialogRoot,
          container: styles.MainOfficeDialogboxContainer,
          paper: styles.MainOfficeDialogboxPaper,
          scrollPaper: styles.MainOfficeScrollPaper,
        }}
      >
        <div className={styles.DialogBoxTitle}>
          <Typography component="h1" variant="h1">
            {assessmentTitle}
          </Typography>
        </div>
        <div className={styles.viewDetailsDialogBoxContext}>
          {assessmentDescription}
        </div>
      </DialogBox>
      {showBackdrop ? <SimpleBackdrop /> : null}
      {formStates.isAssessmentType ? (
        <Alert
          id="warning"
          severity="warning"
          action={
            <IconButton
              id="close"
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleAlertClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {"Please Select an Assessment Type."}
        </Alert>
      ) : null}
      {formStates.isFailed ? (
        <Alert
          id="error"
          severity="error"
          action={
            <IconButton
              id="close"
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleAlertClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {formStates.errMessage}
        </Alert>
      ) : null}

      <Grid container>
        <Grid item xs={9}>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            classes={{ flexContainer: styles.muiTabsFlexContainer }}
          >
            <Tab
              value="Quick Assessments"
              label={
                badgeCount && badgeCount > 0 ? (
                  <Badge
                    badgeContent={badgeCount}
                    color="secondary"
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    classes={{ badge: styles.batchStyle }}
                  >
                    Quick Assessments
                  </Badge>
                ) : (
                  "Quick Assessments"
                )
              }
              classes={{ selected: styles.selectedTab }}
            />
            <Tab
              value="Advanced Assessments"
              label="Advanced Assessments"
              classes={{ selected: styles.selectedTab }}
            />
          </Tabs>
        </Grid>
        <Grid item xs={3} className={styles.assessmentTypeSearchWrap}>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            onChange={searchChangeHandler}
            onKeyDown={onEnterSearchHandler}
            value={searchAssessment}
            autoComplete="off"
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  classes={{
                    positionStart: styles.MuiInputAdornmentPositionStart,
                  }}
                ></InputAdornment>
              ),
            }}
          />
          <Tooltip title="Search" placement="top">
            <div className={styles.crossBtn}>
              <Button
                id="cancel-button"
                variant="contained"
                color="primary"
                onClick={onSearchAssessmentHandler}
              >
                <SearchIcon />
              </Button>
            </div>
          </Tooltip>
        </Grid>
      </Grid>
      {Object.keys(csetAssessmentType).length > 0 ? (
        Object.keys(csetAssessmentType).map((group: any) => {
          return (
            <Grid container spacing={4}>
              <Grid item xs={12} classes={{ item: styles.gridItem }}>
                <Typography
                  component="h1"
                  variant="h1"
                  style={{ marginBottom: "0px !important" }}
                >
                  <div>
                    {group === "Quick Assessments" ? (
                      <div>
                        {pricing?.values?.CRR?.scannable ? (
                          <div
                            style={{
                              fontSize: "18px",
                              fontWeight: 500,
                              marginTop: "5px",
                            }}
                          >
                            {pricing?.values?.CRR?.scan_available?.credit +
                              pricing?.values?.CRR?.scan_available?.free !==
                            0 ? (
                              <b style={{ marginRight: "5px" }}>Credits:</b>
                            ) : (
                              <></>
                            )}

                            {pricing?.values?.CRR?.scan_available?.credit +
                              pricing?.values?.CRR?.scan_available?.free !==
                            0 ? (
                              pricing?.values?.CRR?.scan_available?.credit +
                              pricing?.values?.CRR?.scan_available?.free
                            ) : (
                              <></>
                            )}
                            <br></br>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <div
                        style={{
                          fontSize: "18px",
                          fontWeight: 500,
                          marginTop: "5px",
                        }}
                      >
                        {pricing?.values?.RAA?.scannable ? (
                          <div>
                            {pricing?.values?.RAA?.scan_available?.credit +
                              pricing?.values?.RAA?.scan_available?.free !==
                            0 ? (
                              <b style={{ marginRight: "5px" }}>Credits :</b>
                            ) : (
                              <></>
                            )}

                            {pricing?.values?.RAA?.scan_available?.credit +
                              pricing?.values?.RAA?.scan_available?.free !==
                            0 ? (
                              pricing?.values?.RAA?.scan_available?.credit +
                              pricing?.values?.RAA?.scan_available?.free
                            ) : (
                              <></>
                            )}
                            <br></br>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                  </div>
                </Typography>
              </Grid>
              {csetAssessmentType[group] && csetAssessmentType[group].length > 0
                ? csetAssessmentType[group].map((assType: any, index: any) => {
                    return (
                      <Grid item xs={3} className={styles.cardSection}>
                        <div className={styles.card}>
                          <Card
                            className={`${styles.cardWrap} ${
                              firstClassIndex.includes(index)
                                ? styles.rowFirstCard
                                : ""
                            }  ${
                              lastClassIndex.includes(index)
                                ? styles.rowLastCard
                                : ""
                            }`}
                          >
                            <CardContent className={styles.cardContent}>
                              <Typography
                                style={{ color: "#4b0079" }}
                                gutterBottom
                              >
                                {assType.name}
                              </Typography>
                              <div className={styles.spanStyle}>
                                <Typography variant="body2" component="div">
                                  {assType.description}
                                </Typography>
                              </div>
                            </CardContent>
                            <CardActions className={styles.cardActions}>
                              <Tooltip title="Create Assessment">
                                <PlayCircleIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "40px",
                                  }}
                                  onClick={() => {
                                    handleSubmit(assType);
                                  }}
                                />
                              </Tooltip>
                              <Tooltip title="View Details">
                                <button
                                  id="Supplement"
                                  style={{
                                    borderStyle: "none",
                                    backgroundColor: "white",
                                    padding: 0,
                                    lineHeight: 1,
                                    display: "flex",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    viewDetailHandler(
                                      assType.name,
                                      assType.description
                                    );
                                  }}
                                >
                                  <svg
                                    viewBox="0 0 30 30"
                                    style={{
                                      height: "42px",
                                      width: "38px",
                                      paddingTop: "3px",
                                    }}
                                  >
                                    <path
                                      fill="#fff"
                                      stroke="transparent"
                                      stroke-width="0"
                                      transform="scale(.75)"
                                      d="M18.879,29.328C17.64,29.934 16.341,30.24 14.986,30.24 14.044,30.24 13.424,29.824 13.125,28.99 12.927,28.434 13.014,27.26 13.386,25.472L15.071,17.634C15.214,16.934 15.287,16.422 15.287,16.09 15.287,15.614 15.063,15.364 14.615,15.336L13.088,15.228C12.989,15.05 12.945,14.794 12.958,14.466 12.972,14.134 13.039,13.892 13.164,13.74 15.756,13.402 17.941,13.236 19.718,13.236 20.239,13.236 20.499,13.506 20.499,14.044 20.499,14.24 20.462,14.516 20.384,14.868 20.308,15.22 20.206,15.686 20.077,16.27 19.949,16.85 19.846,17.31 19.772,17.65L18.119,25.136C17.758,26.69 17.807,27.464 18.263,27.464 18.49,27.464 18.877,27.294 19.429,26.954 19.981,26.612 20.487,26.19 20.948,25.682 21.171,25.682 21.391,25.802 21.608,26.042 21.825,26.286 21.971,26.518 22.045,26.74 21.175,27.856 20.121,28.718 18.879,29.328 M16.841,5.47C17.307,4.998 17.962,4.764 18.804,4.764 19.538,4.764 20.133,5.024 20.592,5.546 21.052,6.068 21.281,6.652 21.281,7.296 21.281,7.856 21.028,8.388 20.518,8.896 20.008,9.406 19.363,9.66 18.582,9.66 17.849,9.66 17.26,9.404 16.812,8.888 16.365,8.374 16.142,7.788 16.142,7.126 16.142,6.496 16.375,5.942 16.841,5.47 M17.5,0C7.835,0 0,7.834 0,17.5 0,27.164 7.835,35 17.5,35 27.166,35 35,27.164 35,17.5 35,7.834 27.166,0 17.5,0"
                                    />
                                  </svg>
                                </button>
                              </Tooltip>
                            </CardActions>
                          </Card>
                        </div>
                      </Grid>
                    );
                  })
                : null}
            </Grid>
          );
        })
      ) : (
        <h2>No Assessment Type Found.</h2>
      )}
    </React.Fragment>
  );
};

export default AssessmentType;
